
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { ApolloProvider } from '@apollo/client';
import type { AppProps } from 'next/app';
import TagManager from 'react-gtm-module';
import 'react-day-picker/lib/style.css';
import '@electro/fleets/styles/globals.css';
import { FleetsAuthProvider } from '@electro/fleets/src/hooks';
import { ToastNotifications, ErrorBoundary } from '@electro/shared-ui-components';
import { client } from '@electro/fleets/client';
import { useEffect } from 'react';
import { CookieConsent, DemoBanner } from '@electro/fleets/src/components';
import { FleetsErrorMessage } from '@electro/fleets/src/components/FleetsErrorMessage/FleetsErrorMessage';
import { useAsPathInitializer } from '@electro/fleets/src/hooks/stores';
import { QueryParamsProvider } from '@electro/fleets/src/hooks/useQueryParams/QueryParamsProvider';
const tagManagerArgs = {
    gtmId: 'GTM-W6SM369',
};
function MyApp({ Component, pageProps }: AppProps) {
    useEffect(() => {
        if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
            TagManager.initialize(tagManagerArgs);
        }
        else
            window.dataLayer = [];
    }, []);
    useAsPathInitializer();
    return (<ApolloProvider client={client}>
      <FleetsAuthProvider>
        <ErrorBoundary ErrorComponent={FleetsErrorMessage}>
          <QueryParamsProvider>
            <ToastNotifications>
              <Component {...pageProps}/>
              <CookieConsent />
              <DemoBanner />
            </ToastNotifications>
          </QueryParamsProvider>
        </ErrorBoundary>
      </FleetsAuthProvider>
    </ApolloProvider>);
}
const __Next_Translate__Page__1928af18f9c__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__1928af18f9c__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  