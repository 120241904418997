import { useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import { formatDateForQuery } from '@electro/fleets/src/utils/formatters'

import {
  DateRangeType,
  useToastNotification,
  Tooltip,
  DataGridColumnDef,
  DataGridChangeParams,
} from '@electro/shared-ui-components'
import {
  DriverConsumptionReportSummary,
  DriverConsumptionReportControls,
  DriverConsumptionReportHeadline,
  DownloadReportButton,
  DataGridWithPagination,
} from '@electro/fleets/src/components'

import {
  DriversReceiptsAggregateConnectionTypeOrderingFields as DriverReceiptsAggregateOrderByEnum,
  OrderingDirectionEnum,
  useFleetsBusinessEntityReceiptsAggregateLazyQuery,
  useFleetsDriversReceiptsAggregateLazyQuery,
  useFleetsBusinessEntityLazyQuery,
} from '@electro/fleets/generated/graphql'

import { useDateRangeStore } from '@electro/fleets/src/hooks/stores'
import { useMount } from '@electro/shared/hooks'
import { ChevronRightIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/solid'
import useTranslation from 'next-translate/useTranslation'
import { isDemoMode } from '@electro/fleets/src/utils/envFlagCheck'
import { useDriversConsumptionQueryParams } from '@electro/fleets/src/hooks/useDriversConsumptionQueryParams'

export interface HandleOrderChange<T> {
  nextOrderBy: T
}

interface UpdateAggregateReceiptsReportArgs {
  dateRange: DateRangeType
  first?: number
  offset?: number
  ordering?: {
    orderBy: DriverReceiptsAggregateOrderByEnum
    direction: OrderingDirectionEnum
  }
}

/** NOTE: This is a hardcoded constant for demo purposes only:
 * It's based on some pretty broad assumptions.
 * Based on manufacture efficiency from peugeot website for the peugeot e-xpert 75kwh battery van.
 * Efficiency constant = 207miles/ 75kwh = 2.76.
 */
const TEMPORARY_HARDCODED_EFFICIENCY_CONSTANT = 2.76

const styles = {
  row: 'hover:cursor-pointer',
  chevronIcon: 'text-white group-hover:text-tertiary w-6 h-6',
  unitText: 'font-light text-sm mr-2',
}
export const DriversConsumptionReport = () => {
  const { t } = useTranslation('common')
  const router = useRouter()

  const dateRange = useDateRangeStore((state) => state.dateRange)
  const dateLimits = useDateRangeStore((state) => state.dateLimits)

  const setDateRange = useDateRangeStore((state) => state.setDateRange)
  const setDateLimits = useDateRangeStore((state) => state.setDateLimits)

  const { showToastNotification } = useToastNotification()

  const [
    fleetsDriversReceiptsAggregateQuery,
    {
      data: fleetsDriversReceiptsData,
      loading: fleetsDriversReceiptsLoading,
      error: fleetsDriversReceiptsError,
    },
  ] = useFleetsDriversReceiptsAggregateLazyQuery()

  const [fleetsBusinessEntityReceiptsAggregateQuery, fleetsBusinessReceipts] =
    useFleetsBusinessEntityReceiptsAggregateLazyQuery()

  const [fetchBusinessEntity, { data }] = useFleetsBusinessEntityLazyQuery()
  useMount(() => fetchBusinessEntity())

  /**
   * NOTE:
   * Temporary disable demo ordering for demo mode
   * until BE can fix ordering with stub data.
   */
  const tempDisableDemoOrdering = isDemoMode
  const { queryParams, syncQueryParams } = useDriversConsumptionQueryParams()

  const dateBusinessRegistered = useMemo(() => {
    if (data) {
      return new Date(data?.fleetsBusinessEntity?.registeredAt)
    }

    return null
  }, [data])

  useEffect(() => {
    setDateLimits({
      startDate: dateBusinessRegistered,
      endDate: new Date(),
    })
  }, [data, dateBusinessRegistered, setDateLimits])

  useMount(() => {
    const [order] = queryParams.ordering || []

    updateAggregateReceiptsReport({
      dateRange,
      first: queryParams.first,
      offset: queryParams.offset,
      ordering: order,
    })
  })

  const updateAggregateReceiptsReport = async ({
    dateRange: nextDateRange,
    first,
    offset,
    ordering,
  }: UpdateAggregateReceiptsReportArgs) => {
    const nextFormattedDateRange = {
      startDate: formatDateForQuery(nextDateRange.startDate),
      endDate: formatDateForQuery(nextDateRange.endDate),
    }

    syncQueryParams({ first, offset, ordering: [ordering], ...nextFormattedDateRange })

    await fleetsDriversReceiptsAggregateQuery({
      variables: {
        ...nextFormattedDateRange,
        first,
        offset,
        ordering: ordering ? [ordering] : [],
      },
    })

    await fleetsBusinessEntityReceiptsAggregateQuery({
      variables: {
        ...nextFormattedDateRange,
      },
    })

    setDateRange({
      startDate: nextDateRange.startDate,
      endDate: nextDateRange.endDate,
    })
  }

  const handleDateRangeChange = (dateRangeEvent: DateRangeType) => {
    const [order] = queryParams.ordering || []

    updateAggregateReceiptsReport({
      dateRange: dateRangeEvent,
      first: queryParams.first,
      offset: queryParams.offset,
      ordering: order,
    })
  }

  const showReportActions = useMemo(
    () =>
      fleetsDriversReceiptsData?.fleetsDriversReceiptsAggregate?.edges?.length > 0 &&
      !fleetsDriversReceiptsLoading,
    [fleetsDriversReceiptsData, fleetsDriversReceiptsLoading],
  )

  useEffect(() => {
    if (fleetsDriversReceiptsError)
      showToastNotification({
        heading: t('errors.common.error'),
        variant: 'error',
        body: t('errors.common.could_not_fetch_data'),
      })
  }, [fleetsDriversReceiptsError, showToastNotification, t])

  const handleDataGridChange = ({
    first,
    offset,
    ordering,
  }: DataGridChangeParams<DriverReceiptsAggregateOrderByEnum>) => {
    const [order] = ordering ?? []

    updateAggregateReceiptsReport({ dateRange, first, offset, ordering: order })
  }

  const handleRowClick = (row) => {
    router.push({
      pathname: `/dashboard/reporting/driver/${row.driver.pk}`,
      query: {
        startDate: formatDateForQuery(dateRange.startDate),
        endDate: formatDateForQuery(dateRange.endDate),
      },
    })
  }

  const columns: DataGridColumnDef[] = useMemo(
    () => [
      {
        name: t('common.name'),
        field: 'driver',
        ...(tempDisableDemoOrdering ? {} : { orderField: DriverReceiptsAggregateOrderByEnum.Name }),
        valueGetter: (driver) => `${driver.user.firstName} ${driver.user.lastName}`,
      },
      {
        name: t('common.charges'),
        field: 'numberOfReceipts',
        ...(tempDisableDemoOrdering
          ? {}
          : { orderField: DriverReceiptsAggregateOrderByEnum.NumberOfReceipts }),
      },
      {
        name: t('common.cost'),
        field: 'totalCost',
        ...(tempDisableDemoOrdering
          ? {}
          : { orderField: DriverReceiptsAggregateOrderByEnum.TotalCost }),
        renderCell: (totalCost) => (
          <>
            <span className={styles.unitText}>£</span>
            {totalCost}
          </>
        ),
      },
      {
        name: t('common.consumption'),
        field: 'totalConsumption',
        ...(tempDisableDemoOrdering
          ? {}
          : { orderField: DriverReceiptsAggregateOrderByEnum.TotalConsumption }),
        renderCell: (totalConsumption) => (
          <>
            {totalConsumption}
            <span className={styles.unitText}>kWh</span>
          </>
        ),
      },
      {
        name: t('reporting.drivers_report.price_per_kwh'),
        field: 'pricePerKwh',
        renderCell: (_, row) => {
          const avgConsumption = Math.round(
            (parseFloat(row.totalCost) * 100) / parseFloat(row.totalConsumption),
          )

          return (
            <>
              {avgConsumption}
              <span className={styles.unitText}>p/kWh</span>
            </>
          )
        },
      },
      {
        name: t('reporting.drivers_report.price_per_mile'),
        field: 'pricePerMile',
        renderCell: (_, row) => {
          const avgConsumption = Math.round(
            (parseFloat(row.totalCost) * 100) / parseFloat(row.totalConsumption),
          )
          const goodEnoughForDemoCostPerMile = Math.round(
            avgConsumption / TEMPORARY_HARDCODED_EFFICIENCY_CONSTANT,
          )

          return (
            <div className="flex items-center">
              {goodEnoughForDemoCostPerMile}
              <span className={styles.unitText}>p/mile</span>
              <Tooltip>
                <Tooltip.Trigger ariaLabel={t('reporting.drivers_report.tooltip_aria_label')}>
                  <QuestionMarkCircleIcon className="w-4 h-4 text-secondary-light" />
                </Tooltip.Trigger>
                <Tooltip.Body>
                  {t('reporting.drivers_report.tooltip.body.based_on_E-xpert')}
                </Tooltip.Body>
              </Tooltip>
            </div>
          )
        },
      },
      {
        name: '',
        field: 'icon',
        renderCell: () => <ChevronRightIcon className={styles.chevronIcon} />,
      },
    ],
    [t, tempDisableDemoOrdering],
  )

  const rows = useMemo(
    () =>
      fleetsDriversReceiptsData?.fleetsDriversReceiptsAggregate?.edges?.map((edge) => edge.node),
    [fleetsDriversReceiptsData],
  )

  return (
    <div className="grid w-full grid-cols-1 gap-8" data-testid="drivers-consumption-report">
      <div className="flex flex-col justify-start lg:grid lg:grid-cols-2">
        <DriverConsumptionReportHeadline
          loading={fleetsDriversReceiptsLoading}
          totalCount={fleetsDriversReceiptsData?.fleetsDriversReceiptsAggregate.totalCount}
        />
        <div className="text-right lg:col-span-1">
          <DriverConsumptionReportControls
            {...{
              handleDateRangeChange,
              dateRange,
              dateLimits,
            }}
          />
        </div>
      </div>
      <div className="flex justify-center h-10 lg:justify-end">
        {showReportActions ? (
          <DownloadReportButton
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            reportType="business"
            business={data?.fleetsBusinessEntity}
            driver={null}
          />
        ) : null}
      </div>
      <DriverConsumptionReportSummary {...{ fleetsBusinessReceipts }} />
      <DataGridWithPagination<DriverReceiptsAggregateOrderByEnum>
        data-testid="driver-list"
        loading={fleetsDriversReceiptsLoading}
        loadingMessage={t('reporting.drivers_report.table.loading_message')}
        error={!!fleetsDriversReceiptsError}
        errorMessage={fleetsDriversReceiptsError?.message}
        emptyTable={
          fleetsDriversReceiptsData &&
          fleetsDriversReceiptsData?.fleetsDriversReceiptsAggregate.totalCount === 0
        }
        emptyTableMessage={t('reporting.drivers_report.table.empty_table')}
        columns={columns}
        rows={rows}
        totalCount={fleetsDriversReceiptsData?.fleetsDriversReceiptsAggregate?.totalCount}
        initialState={{
          offset: queryParams.offset,
          ordering: queryParams.ordering,
        }}
        onRowClick={handleRowClick}
        onChange={handleDataGridChange}
        resultPerPage={queryParams.first}
      />
    </div>
  )
}
