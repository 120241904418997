import {
  Card,
  Skeleton,
  Typography,
  DataGridColumnDef,
  Tag,
  TagColours,
  DataGridChangeParams,
} from '@electro/shared-ui-components'
import {
  EjnEjnCardOrderStatusChoices,
  useFleetsCardOrdersQuery,
  EjnEjnTokenStatusChoices,
  useFleetsDriversTokensLazyQuery,
} from '@electro/fleets/generated/graphql'
import capitalize from '@electro/shared/utils/capitalize'
import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'
import { useMount } from 'react-use'
import { useMemo } from 'react'
import { useQueryParams } from '@electro/fleets/src/hooks/useQueryParams'
import { QueryKeyEnum } from '@electro/fleets/src/hooks/useQueryParams/queryParams.types'
import { DataGridWithPagination } from '../DataGridWithPagination'

const styles = {
  root: 'col-span-4',
  metricWidget: {
    desktop: {
      default: 'flex items-center lg:text-lg lg:flex-row lg:text-left lg:h-32',
      total: 'lg:mr-2 font-normal text-4xl',
      description: 'text-sm font-normal lg:text-lg !leading-tight',
    },
    mobile: {
      default: 'flex-col justify-center text-center p-10 h-14',
    },
  },
}

const statusTagColors: Record<EjnEjnTokenStatusChoices, TagColours> = {
  [EjnEjnTokenStatusChoices.Active]: 'green',
  [EjnEjnTokenStatusChoices.Cancelled]: 'red',
  [EjnEjnTokenStatusChoices.Pending]: 'orange',
  [EjnEjnTokenStatusChoices.Unassigned]: 'lightGrey',
}

export const ElectrocardsList = () => {
  const { queryParams, syncQueryParams } = useQueryParams(QueryKeyEnum.ElectrocardsListPage)
  const [getElectrocards, { data, loading, error }] = useFleetsDriversTokensLazyQuery()
  const { data: cardOrdersData, loading: cardOrdersLoading } = useFleetsCardOrdersQuery()
  const { t } = useTranslation('common')

  const filterForNewAndPendingOrders = useMemo(
    () =>
      cardOrdersData?.fleetsCardOrders?.edges?.filter(
        (cardOrder) =>
          cardOrder.node.status === EjnEjnCardOrderStatusChoices.New ||
          cardOrder.node.status === EjnEjnCardOrderStatusChoices.Pending,
      ),
    [cardOrdersData],
  )
  const handleDataGridChange = ({ first, offset }: DataGridChangeParams) => {
    getElectrocards({ variables: { first, offset } })
    syncQueryParams({ first, offset })
  }

  useMount(() => {
    const { first, offset } = queryParams

    getElectrocards({
      variables: { first, offset },
    })
  })

  const columns: DataGridColumnDef[] = [
    {
      name: t('electrocards.table.card_number'),
      field: 'number',
    },
    {
      name: t('common.status'),
      field: 'status',
      renderCell: (status) => (
        <Tag
          label={capitalize(status.toLowerCase())}
          colour={statusTagColors[status]}
          className="w-24"
        />
      ),
    },
    {
      name: t('electrocards.table.assigned_to'),
      field: 'driver',
      valueGetter: (driver) => (driver ? `${driver.user.firstName} ${driver.user.lastName}` : '-'),
    },
  ]

  const rows = data?.fleetsDriversTokens?.edges?.map((edge) => edge.node)

  return (
    <div className={styles.root}>
      {loading || cardOrdersLoading ? (
        <div className="flex mb-4 space-x-4">
          <Card
            className={tw(styles.metricWidget.desktop.default, styles.metricWidget.mobile.default)}
          >
            <Skeleton width={180} />
          </Card>
          <Card
            className={tw(styles.metricWidget.desktop.default, styles.metricWidget.mobile.default)}
          >
            <Skeleton width={180} />
          </Card>
          <Card
            className={tw(styles.metricWidget.desktop.default, styles.metricWidget.mobile.default)}
          >
            <Skeleton width={180} />
          </Card>
        </div>
      ) : (
        <div className="grid grid-cols-3 gap-4 mb-4 lg:max-w-3xl">
          <Card
            className={tw(styles.metricWidget.mobile.default, styles.metricWidget.desktop.default)}
            data-testid="metric-widget"
          >
            <Typography variant="h3" as="span" className={styles.metricWidget.desktop.total}>
              {data?.activeTokens.totalCount}
            </Typography>
            <Typography variant="h3" as="span" className={styles.metricWidget.desktop.description}>
              {t('electrocards.widget.cards_active')}
            </Typography>
          </Card>

          <Card
            className={tw(styles.metricWidget.desktop.default, styles.metricWidget.mobile.default)}
            data-testid="metric-widget"
          >
            <Typography variant="h3" as="span" className={styles.metricWidget.desktop.total}>
              {data?.unassignedTokens.totalCount}
            </Typography>
            <Typography variant="h3" as="span" className={styles.metricWidget.desktop.description}>
              {t('electrocards.widget.cards_unassigned')}
            </Typography>
          </Card>

          <Card
            className={tw(styles.metricWidget.desktop.default, styles.metricWidget.mobile.default)}
            data-testid="metric-widget"
          >
            <Typography variant="h3" as="span" className={styles.metricWidget.desktop.total}>
              {filterForNewAndPendingOrders?.length}
            </Typography>
            <Typography variant="h3" as="span" className={styles.metricWidget.desktop.description}>
              {t('electrocards.widget.cards_ordered')}
            </Typography>
          </Card>
        </div>
      )}

      <Card className="overflow-auto">
        <DataGridWithPagination
          resultPerPage={queryParams.first}
          loading={loading}
          loadingMessage={t('common.loading.loading_ellipsis')}
          emptyTable={data?.fleetsDriversTokens?.totalCount === 0}
          emptyTableMessage={t('electrocards.emptyTableMessage')}
          error={!!error}
          errorMessage={t('electrocards.error_message')}
          columns={columns}
          rows={rows}
          totalCount={data?.fleetsDriversTokens?.totalCount}
          onChange={handleDataGridChange}
          initialState={{
            offset: queryParams.offset,
          }}
        />
      </Card>
    </div>
  )
}
