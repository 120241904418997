import {
  Tag,
  Tooltip,
  DataGridColumnDef,
  DataGridChangeParams,
  Ordering,
  TagColours,
} from '@electro/shared-ui-components'
import {
  DriverWidgets,
  QuickSearchToolbar,
} from '@electro/fleets/src/components/DriversListV2/components'
import {
  DriverTypeOrderingFields,
  useFleetsDriversLazyQuery,
  EjnEjnTokenStatusChoices,
} from '@electro/fleets/generated/graphql'
import { useCallback, useMemo } from 'react'
import { useMount } from 'react-use'
import { DevicePhoneMobileIcon } from '@heroicons/react/24/outline'
import { tw } from '@electro/shared/utils/tailwind-merge'

import useTranslation from 'next-translate/useTranslation'
import { useDriversQueryParams } from '@electro/fleets/src/hooks'
import { DataGridWithPagination } from '../DataGridWithPagination'

interface DriversDataGridChangeParams extends DataGridChangeParams<DriverTypeOrderingFields> {
  isActive: boolean
}

const DriversDetailPanel = ({ row }) => <DriverWidgets driver={row} />

const styles = {
  root: 'h-8 w-8 lg:m-0 m-auto',
  active: 'text-green-500',
  inactive: ' opacity-50',
  widget: 'fixed top-0 right-0 h-full w-screen overflow-y-auto z-40',
}

export const DriversListV2 = () => {
  const { t } = useTranslation('common')
  const { queryParams, syncQueryParams } = useDriversQueryParams()
  const [getDrivers, { data, loading, error }] = useFleetsDriversLazyQuery()
  const isActive = queryParams.additionalParams.isActive === 'true' ? true : null

  useMount(() => {
    const { first, offset, ordering, search } = queryParams

    getDrivers({
      variables: {
        first,
        offset,
        ordering,
        search,
        isActive,
      },
    })
  })

  const handleDataGridChange = ({
    first,
    offset,
    ordering,
    search,
  }: DriversDataGridChangeParams) => {
    const nextQueryParamIsActive =
      queryParams.additionalParams.isActive === 'true' ? 'true' : 'null'

    syncQueryParams({
      first,
      offset,
      ordering,
      search,
      additionalParams: { isActive: nextQueryParamIsActive },
    })

    getDrivers({
      variables: {
        first,
        offset,
        ordering,
        search,
        isActive,
      },
    })
  }

  const columns: DataGridColumnDef[] = [
    {
      name: t('common.name'),
      field: 'name',
      orderField: DriverTypeOrderingFields.Name,
      valueGetter: (_, row) => `${row?.user?.firstName} ${row?.user?.lastName}`,
    },
    {
      name: t('common.account'),
      field: 'account',
      renderCell: (_, row) =>
        row?.isActive ? (
          <Tag
            label={t('common.active')}
            colour={'green' as TagColours}
            className="w-18 lg:m-0 m-auto"
            data-testid="activeDriver"
          />
        ) : (
          <Tag
            label={t('common.inactive')}
            colour={'lightGrey' as TagColours}
            className="w-18 lg:m-0 m-auto"
            data-testid="inactiveDriver"
          />
        ),
    },
    {
      name: t('common.electrocard'),
      field: 'electrocard',
      renderCell: (_, row) => {
        const driverHasElectrocard =
          row.ejnCard !== null || row.ejnCard?.status === EjnEjnTokenStatusChoices.Active

        return (
          <Tooltip>
            <Tooltip.Trigger ariaLabel="driver-has-electrocard">
              <Tag
                label={t('tags.name.rfid')}
                colour={
                  driverHasElectrocard ? ('green' as TagColours) : ('lightGrey' as TagColours)
                }
                className="w-16 lg:m-0 m-auto"
              />
            </Tooltip.Trigger>
            <Tooltip.Body>
              {driverHasElectrocard
                ? t('driver.table.tooltip.body.has_electrocard')
                : t('driver.table.tooltip.body.no_electrocard')}
            </Tooltip.Body>
          </Tooltip>
        )
      },
    },
    {
      name: t('common.app'),
      field: 'app',
      renderCell: (_, row) => {
        const driverHasSignUp = row.hasSignedUp

        return (
          <Tooltip>
            <Tooltip.Trigger ariaLabel="driver-is-logged-in">
              <DevicePhoneMobileIcon
                data-testid="device-mobile-icon"
                className={tw({
                  [styles.root]: true,
                  [styles.active]: driverHasSignUp,
                  [styles.inactive]: !driverHasSignUp,
                })}
              />
            </Tooltip.Trigger>
            <Tooltip.Body>
              {driverHasSignUp
                ? t('driver.table.tooltip.body.has_sign_up')
                : t('driver.table.tooltip.body.has_not_sign_up')}
            </Tooltip.Body>
          </Tooltip>
        )
      },
    },
  ]

  const rows = useMemo(() => data?.fleetsDrivers?.edges?.map((edge) => edge?.node), [data])

  const dataGridToolbar = useCallback(
    () => <QuickSearchToolbar getDrivers={getDrivers} />,
    [getDrivers],
  )

  return (
    <div>
      <DataGridWithPagination<DriverTypeOrderingFields>
        loading={loading}
        loadingMessage={t('common.loading.loading_ellipsis')}
        emptyTable={data && data?.fleetsDrivers?.edgeCount === 0}
        emptyTableMessage={t('drivers.table.empty_table')}
        error={!!error}
        errorMessage={t('drivers.table.error_message')}
        resultPerPage={queryParams.first}
        rows={rows}
        columns={columns}
        initialState={{
          offset: queryParams.offset,
          ordering: queryParams.ordering as Ordering<DriverTypeOrderingFields>[],
          searchText: queryParams.search,
        }}
        totalCount={data?.fleetsDrivers?.totalCount}
        detailPanel={DriversDetailPanel}
        onChange={handleDataGridChange}
        toolbar={dataGridToolbar}
      />
    </div>
  )
}
